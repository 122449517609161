import { FORM_LABELS } from "../constants";
import { StyledSpan } from "../GlobalStyles";

export const purchaseRequisitionForm = [
  {
    name: "pharmacologicalName",
    label: (
      <span>
        Pharmacological Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Pharmacological Name is required" },
    rules: {
      pattern: {
        value: /^[A-Z0-9\s&-]+$/, // Allows uppercase letters, numbers, spaces, &, and hyphen
        message: "Only uppercase letters, numbers, &, and hyphen are allowed",
      },
    },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
  },
  {
    name: "brandName",
    label: (
      <span>
        Brand Name<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Brand Name is required" },
    rules: {
      pattern: {
        value: /^[A-Z0-9-\/]+$/, // Allows uppercase letters, numbers, hyphen, and slash
        message:
          "Only uppercase letters, numbers, hyphen, and slash are allowed",
      },
    },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
  },
  {
    name: "dose",
    label: "Dose",
    type: "text",
    // validation: { required: "Dose is required", min: 1 },
    rules: {
      pattern: {
        value: /^[A-Za-z0-9\s]+$/, // Allows alphabets, numbers, and spaces
        message: "Only alphabets, numbers, and spaces are allowed",
      },
    },
  },
  {
    name: "form",
    label: (
      <span>
        Form<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    options: [
      {
        value: "",
        label: "Select",
      },
      { value: "Tablets", label: "Tablets" },
      { value: "Injections", label: "Injections" },
      { value: "Inhalers", label: "Inhalers" },
      { value: "Capsules", label: "Capsules" },
      { value: "Syrup", label: "Syrup" },
      { value: "Drops", label: "Drops" },
      { value: "Ointment", label: "Ointment" },
    ],
    validation: { required: "Form is required" },
    rules: {
      pattern: {
        value: /^[A-Za-z\s]+$/, // Allows only alphabetic characters and spaces
        message: "Only alphabets and spaces are allowed",
      },
    },
  },
  {
    name: "quantity",
    label: (
      <span>
        Quantity<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    validation: { required: "Quantity is required", min: 1 },
    rules: {
      min: {
        value: 1,
        message: "Quantity must be greater than 0", // Error message if validation fails
      },
      pattern: {
        value: /^[a-zA-Z0-9\s]+$/,
        message: "Special characters are not allowed",
      },
    },
  },
];

export const quotationForm = [
  {
    name: "pharmacologicalName",
    label: (
      <span>
        Pharmacological Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    pharmacologicalNameId: "",
    validation: {
      required: "Pharmacological Name is required",
    },
    rules: {
      pattern: {
        value: /^[A-Z0-9\s&-]+$/, // Allows uppercase letters, numbers, spaces, &, and hyphen
        message: "Only uppercase letters, numbers, &, and hyphen are allowed",
      },
    },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
  },
  {
    name: "brandName",
    label: (
      <span>
        Brand Name<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: {
      required: "Brand Name Name is required",
    },
    brandName: "",
    rules: {
      pattern: {
        value: /^[A-Z0-9\s&-]+$/, // Allows uppercase letters, numbers, hyphen, and slash
        message:
          "Only uppercase letters, numbers, hyphen, and slash are allowed",
      },
    },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
  },
  {
    name: "dose",
    label: (
      <span>
        Dose
      </span>
    ),
    type: "text",
    isDisabled: true,
    // validation: {
    //   required: "Dose is required",
    //   min: 1,
    // },
    rules: {
      pattern: {
        value: /^[A-Za-z0-9\s]+$/, // Allows alphabets, numbers, and spaces
        message: "Only alphabets, numbers, and spaces are allowed",
      },
    },
  },
  {
    name: "form",
    label: (
      <span>
        Form<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    options: [
      {
        value: "",
        label: "Select",
      },
      { value: "tablets", label: "Tablets" },
      { value: "injections", label: "Injections" },
      { value: "inhalers", label: "Inhalers" },
      { value: "capsules", label: "Capsules" },
      { value: "Syrup", label: "Syrup" },
      { value: "drops", label: "Drops" },
      { value: "ointment", label: "Ointment" },
    ],
    validation: {
      required: "Form is required",
    },
    rules: {
      pattern: {
        value: /^[A-Za-z\s]+$/, // Allows only alphabetic characters and spaces
        message: "Only alphabets and spaces are allowed",
      },
    },
  },
  {
    name: "quantity",
    label: (
      <span>
        Quantity<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: {
      required: "Quantity is required",
    },
    rules: {
      min: {
        value: 1,
        message: "Quantity must be greater than 0", // Error message if validation fails
      },
      pattern: {
        value: /^[a-zA-Z0-9\s]+$/,
        message: "Special characters are not allowed",
      },
    },
  },
  {
    name: "mrp",
    label: (
      <span>
        MRP<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: {
      required: "MRP is required",
    },
    rules: {
      min: {
        value: 1,
        message: "MRP must be greater than 0", // Error message if validation fails
      },
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
    },
  },
  {
    name: "ptr",
    label: (
      <span>
        PTR<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: {
      required: "PTR is required",
    },
    rules: {
      min: {
        value: 1,
        message: "PTR must be greater than 0", // Error message if validation fails
      },
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
    },
  },
  {
    name: "pts",
    label: (
      <span>
        PTS<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: {
      required: "PTS is required",
    },
    rules: {
      min: {
        value: 1,
        message: "PTS must be greater than 0", // Error message if validation fails
      },
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
    },
  },
  {
    name: "gst",
    isTypingRestricted: true,
    label: (
      <span>
        GST %<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "defaultSelect",
    isDisabled: true,
    options: [
      { value: "5", label: "5" },
      { value: "12", label: "12" },
      { value: "18", label: "18" },
      { value: "28", label: "28" },
    ],
    validation: {
      required: "GST is required",
    },
    rules: {
      pattern: {
        value: /^[0-9]+$/, // Only numbers allowed
        message: "Only numbers are allowed",
      },
    },
    canCreateNew: false
  },
  {
    name: "discount",
    label: "Discount %",
    type: "number",
    isDisabled: true,
    // validation: {
    //   required: "Discount is required",
    //   min: 0,
    //   max: 100,
    // },
    rules: {
      min: {
        value: 0,
        message: "Discount must be equal or greater than 0%", // Error message if validation fails
      },
      max: {
        value: 100,
        message: "Discount must be less than 100%",
      },
      pattern: {
        value: /^\d*\.?\d+$/, // Only numbers and decimal numbers
        message: "Only numbers and decimal numbers are allowed",
      },
    },
  },
];

export const invoiceForm = [
  {
    name: "pharmacologicalName",
    label: (
      <span>
        Pharmacological Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: {
      required: "Pharmacological Name is required",
      pattern: {
        value: /^[A-Z0-9&-]+$/, // Allows only uppercase letters, numbers, &, and hyphen
        message:
          "Only uppercase letters, numbers, &, and hyphens are allowed. No lowercase letters or special characters.",
      },
    },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
    style: {
      width: "170px",
    },
  },
  {
    name: "brandName",
    label: (
      <span>
        Brand Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Brand Name is required" },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
    style: {
      width: "170px",
    },
  },
  {
    name: "batchNo",
    label: (
      <span>
        Batch No <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    validation: {
      required: "Batch No is required",
      pattern: {
        value: /^[A-Z0-9-]+$/, // Allows uppercase letters, numbers, and hyphens
        message: "Only uppercase letters, numbers, and hyphens are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "hsnCode",
    label: (
      <span>
        HSN Code <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    validation: {
      required: "HSN Code is required",
      validate: {
        minLength: (value) =>
          value.length >= 4 || "HSN code must be at least 4 digits",
        maxLength: (value) =>
          value.length <= 8 || "HSN code not more than 8 digits",
        isNumber: (value) =>
          /^[0-9]+$/.test(value) || "Hsn Code must be a number",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "expiry",
    label: (
      <span>
        Expiry <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "date",
    validation: { required: "Expiry is required" },
  },
  {
    name: "unitsOrStrips",
    label: (
      <span>
        Units / Strips <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",  // Changed from "number" to "text" for comma formatting
    validation: {
      required: "Units / Strips is required",
      min: {
        value: 1,
        message: "Units / Strips must be greater than 0",
      },
      // valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^[0-9,]+$/, // Allow numbers and commas
        message: "Only numbers and commas are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "noOfStrips",
    label: (
      <span>
        No of Strips <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",  // Changed from "number" to "text" for comma formatting
    validation: {
      required: "No of Strips is required",
      min: {
        value: 1,
        message: "No of Strips must be greater than 0",
      },
      // valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^[0-9,]+$/, // Allow numbers and commas
        message: "Only numbers and commas are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "freeStrips",
    label: "Free Strips",
    type: "text",  // Changed from "number" to "text" for comma formatting
    validation: {
      min: {
        value: 0,
        message: "Free strips must be equal to or greater than 0",
      },
      // valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^[0-9,]+$/, // Allow numbers and commas
        message: "Only numbers and commas are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "mrpPerStrip",
    label: (
      <span>
        MRP Per Strip <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",  // Changed from "number" to "text" for comma formatting
    validation: {
      required: "MRP Per Strip is required",
      min: {
        value: 1,
        message: "MRP Per Strip must be greater than 0",
      },
      // valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "pricePerStrip",
    label: (
      <span>
        Price Per Strip <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",  // Changed from "number" to "text" for comma formatting
    validation: {
      required: "Price Per Strip is required",
      min: {
        value: 1,
        message: "Price Per Strip must be greater than 0",
      },
      // valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "discount",
    label: "Discount %",
    type: "number",
    validation: {
      // required: "Discount is required",
      min: {
        value: 0,
        message: "Discount must be equal to or greater than 0",
      },
      max: {
        value: 100,
        message: "Discount must be less than or equal to 100",
      },
      valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^\d*\.?\d+$/, // Allows numbers and decimal numbers
        message: "Only numbers and decimal numbers are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
  {
    name: "gst",
    label: (
      <span>
        GST %<StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "GST is required" },
    options: [
      { value: "0", label: "0%" },
      { value: "5", label: "5%" },
      { value: "12", label: "12%" },
      { value: "18", label: "18%" },
      { value: "28", label: "28%" },
    ],
    style: {
      width: "70px",
    },
  },
  {
    name: "netPrice",
    label: (
      <span>
        Net Price <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",  // Changed from "number" to "text" for comma formatting
    validation: {
      required: "Net price is required",
      min: {
        value: 1,
        message: "Net Price must be greater than 0",
      },
      // valueAsNumber: true, // Ensure the value is treated as a number
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
    },
    style: {
      width: "70px",
    },
  },
];


export const billingForm = [
  {
    name: "pharmacologicalName",
    label: (
      <span>
        Pharmacological Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Pharmacological Name is required" },
    options: [{ value: "", label: "Select" }],
    isDisabled: false,
  },
  {
    name: "brandName",
    label: (
      <span>
        Brand Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Brand Name is required" },
    options: [{ value: "", label: "Select" }],
    isDisabled: false,
  },
  {
    name: "batchNo",
    label: (
      <span>
        Batch No <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: { required: "Batch No is required", min: 1 },
    rules: {
      pattern: {
        value: /^[A-Z0-9-]+$/, // Allows uppercase letters, numbers, and hyphens
        message: "Only uppercase letters, numbers, and hyphens are allowed",
      },
    },
    styles: {
      display: "grid",
    },
  },
  {
    name: "hsnCode",
    label: (
      <span>
        HSN Code <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    isDisabled: true,
    validation: { required: "HSN Code is required", min: 1 },
    styles: {
      display: "grid",
    },
  },
  {
    name: "price",
    label: (
      <span>
        Price <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    rules: {
      min: {
        value: 1,
        message: "Price must be greater than 0",
      },
      pattern: {
        value: /^[0-9]+$/, // Only numbers allowed
        message: "Only numbers are allowed",
      },
    },
    styles: {
      display: "grid",
    },
  },
  {
    name: "quantity",
    label: (
      <span>
        Quantity <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: false,
    validation: { required: "Qunatity is required" },
    rules: {
      min: {
        value: 1,
        message: "Price must be greater than 0",
      },
      pattern: {
        value: /^[0-9]+$/, // Only numbers allowed
        message: "Only numbers are allowed",
      },
    },
    styles: {
      display: "grid",
    },
  },
  {
    name: "total",
    label: (
      <span>
        Total <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    isDisabled: true,
    validation: { required: "Total is required" },
    rules: {
      min: {
        value: 1,
        message: "Price must be greater than 0",
      },
      pattern: {
        value: /^[0-9]+$/, // Only numbers allowed
        message: "Only numbers are allowed",
      },
    },
    styles: {
      display: "grid",
    },
  },
  {
    name: "discount",
    label: "Discount",
    type: "number",
    isDisabled: false,
    validation: {
      min: 0,
      max: 100,
    },
    rules: {
      min: {
        value: 0,
        message: "Discount must be equal or greater than 0%", // Error message if validation fails
      },
      max: {
        value: 100,
        message: "Discount must be less than 100%",
      },
      pattern: {
        value: /^\d*\.?\d+$/, // Only numbers and decimal numbers
        message: "Only numbers and decimal numbers are allowed",
      },
    },
    styles: {
      display: "grid",
    },
  },
  {
    name: "amount",
    label: (
      <span>
        Amount <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "type",
    isDisabled: true,
    styles: {
      display: "grid",
    },
  },
];

export const patientDetailsForm = [
  {
    name: "phone",
    label: (
      <span>
        Phone <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    rules: {
      required: "Phone Number is required",
      pattern: {
        value: /^[6-9]\d{9}$/, // Starts with 6-9, 10 digits
        message: "Invalid phone number. Must be 10 digits starting with 6-9",
      },
    },
    style: {
      position: 'relative'
    },
  },
  {
    name: "patientFirstName",
    label: (
      <span>
        Patient First Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    rules: { required: "Patient First Name is required" },
  },
  {
    name: "PatientLastName",
    label: (
      <span>
        Patient Last Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    rules: { required: "Patient Last Name is required" },
  },
  {
    name: "gender",
    label: "Gender",
    type: "select",
    options: [
      { value: "", label: "Select" },
      { value: "male", label: "Male" },
      { value: "female", label: "Female" },
      { value: "others", label: "Others" },
    ],
    rules: {}, // No validation for gender
  },
  {
    name: "age",
    label: (
      <span>
        Age <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    rules: { required: "Age is required" },
  },
  {
    name: "email",
    label: "Email",
    type: "text",
    rules: {
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email format",
      },
    },
  },
  {
    name: "referredDoctor",
    label: "Referred Doctor",
    type: "text",
    rules: {}, // Optional field, no validation
  },
  {
    name: "otcOrRx",
    label: "OTC / Rx",
    type: "select",
    options: [
      { value: "", label: "Select" },
      { value: "otc", label: "OTC" },
      { value: "rx", label: "RX" },
    ],
    rules: { required: "Please select OTC or RX" },
  },
];

export const billingSummaryForm = [
  {
    name: "discount",
    label: "Bill Discount",
    type: "number",
    isDisabled: false, // Editable
    rules: {
      min: {
        value: 0,
        message: "Discount must be equal or greater than 0%",
      },
      max: {
        value: 100,
        message: "Discount must be less than 100%",
      },
      pattern: {
        value: /^\d*\.?\d+$/,
        message: "Only numbers and decimal numbers are allowed",
      },
    },
  },
  {
    name: "netPrice",
    label: "Net Price",
    type: "text",
    isDisabled: true, // Disabled
    validation: { required: "Net price is required" },
  },
  {
    name: "roundOff",
    label: "Round Off",
    type: "number",
    isDisabled: true, // Disabled
    validation: {},
  },
  {
    name: "billAmount",
    label: "Bill Amount",
    type: "text",
    isDisabled: true, // Disabled
    validation: { required: "Bill amount is required" },
  },
  {
    name: "paymentMode",
    label: "Payment Mode",
    type: "select",
    isDisabled: false, // Editable
    options: [
      { value: "cash", label: "Cash" },
      { value: "card", label: "Card" },
      { value: "upi", label: "UPI" },
    ],
    validation: { required: "Payment method is required" },
  },
  {
    name: "paidAmount",
    label: "Paid Amount",
    type: "text",
    isDisabled: false, // Editable
    validation: { required: "Paid amount is required" },
  },
  {
    name: "balanceAmount",
    label: "Balance Amount",
    type: "text",
    isDisabled: true, // Disabled
    validation: {},
  },
  {
    name: "addRemarks",
    label: "Add Remarks",
    type: "text",
    isDisabled: false, // Editable
    validation: {
      maxLength: {
        value: 300,
        message: "Remarks cannot exceed 300 characters",
      },
    },
  },
];

export const AddMedicineBilling = [
  {
    name: "pharmacologicalName",
    label: (
      <span>
        Pharmacological Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Pharmacological Name is required" },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
  },
  {
    name: "brandName",
    label: (
      <span>
        Brand Name <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "select",
    validation: { required: "Brand Name is required" },
    options: [
      {
        value: "",
        label: "Select",
      },
    ],
  },
  {
    name: "batchNo",
    label: (
      <span>
        Batch No <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "text",
    validation: { required: "Batch No is required" },
    rules: {
      pattern: {
        value: /^[A-Z0-9-]+$/, // Allows uppercase letters, numbers, and hyphens
        message: "Only uppercase letters, numbers, and hyphens are allowed",
      },
    },
  },
  {
    name: "hsnCode",
    label: (
      <span>
        HSN Code <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    validation: { required: "HSN Code is required" },
    rules: {
      pattern: {
        value: /^\d{1,8}$/, // Allows only numbers with a maximum of 8 digits
        message: "Hsn Code must be a number with up to 8 digits",
      },
    },
  },
  {
    name: "price",
    label: "Price",
    type: "number",
    validation: {},
  },
  {
    name: "quantity",
    label: (
      <span>
        Quantity <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    validation: { required: "Quantity is required", min: 1 },
    rules: {
      min: {
        value: 1,
        message: "Quantity must be greater than 0", // Error message if validation fails
      },
      pattern: {
        value: /^[a-zA-Z0-9\s]+$/,
        message: "Special characters are not allowed",
      },
    },
  },
  {
    name: "total",
    label: "Total",
    type: "number",
    validation: {},
  },
  {
    name: "discount",
    label: "Discount",
    type: "number",
    // validation: {
    //   min: 0,
    //   max: 100,
    // },
    rules: {
      min: {
        value: 0,
        message: "Discount must be equal or greater than 0%", // Error message if validation fails
      },
      max: {
        value: 100,
        message: "Discount must be less than 100%",
      },
      pattern: {
        value: /^\d*\.?\d+$/, // Only numbers and decimal numbers
        message: "Only numbers and decimal numbers are allowed",
      },
    },
  },
  {
    name: "amount",
    label: (
      <span>
        Amount <StyledSpan> *</StyledSpan>
      </span>
    ),
    type: "number",
    validation: { required: "Amount is required", min: 1 },
  },
];

export const AddPaymentDetails = [
  {
    name: "amount",
    label: "Amount",
    type: "text",
    validation: { required: "Amount is required", min: 1,
      pattern: {
        value: /^(\d{1,3}(,\d{3})*|(\d+))(\.\d+)?$/, // Allows numbers with commas and decimals
        message: "Only valid numbers with commas and decimal points are allowed",
      },
     },
  },
  {
    name: "paymentMode",
    label: "Payment Mode",
    type: "select",
    options: [
      { value: "", label: "Select" },
      { value: "cash", label: "Cash" },
      { value: "card", label: "card" },
      { value: "upi", label: "UPI" },
    ],
    validation: { required: "Payment mode is required" },
  },
  {
    name: "untNumber",
    label: "UTR Number",
    type: "number",
    validation: { required: "UTR Number is required", min: 1 },
  },
  {
    name: "paymentDate",
    label: "Payment Date",
    type: "date",
    validation: { required: "Payment Date is required" },
  },
  {
    name: "remarks",
    label: "Remarks",
    type: "text",
  },
];

export const formOptions = [
  {
    value: "",
    label: "Select",
  },
  { value: "Tablets", label: "Tablets" },
  { value: "Injections", label: "Injections" },
  { value: "Inhalers", label: "Inhalers" },
  { value: "Capsules", label: "Capsules" },
  { value: "Syrup", label: "Syrup" },
  { value: "Drops", label: "Drops" },
  { value: "Ointment", label: "Ointment" },
];
