import { useState, useRef } from "react";
import { formOptions } from "../data/fromData";

export const hasAllRequiredKeysInList = (data, requiredKeys) => {
  return data.every((item) =>
    requiredKeys.every((key) => item.hasOwnProperty(key))
  );
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth is zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const updateTableHeight = (
  setHeight,
  minHeight = 500,
  percentageOfWindow = 0.5
) => {
  const windowHeight = window.innerHeight;
  const dynamicHeight = Math.max(minHeight, windowHeight * percentageOfWindow); // Minimum height or 50% of window height
  setHeight(dynamicHeight);
};

export const parseDate = (dateString) => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day); // Month is 0-indexed
};

export const filterInventoryByExpiry = (inventory) => {
  const currentDate = new Date(); // Today's date
  const threeMonthsFromNow = new Date();
  threeMonthsFromNow.setMonth(currentDate.getMonth() + 3);

  return inventory.filter((inv) => {
    const [day, month, year] = inv.expiry.split("/"); // Split DD/MM/YYYY
    const expiryDate = new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD format

    if (isNaN(expiryDate)) {
      return false;
    }

    // Check if the expiry is between now and 3 months from now
    return expiryDate > currentDate && expiryDate <= threeMonthsFromNow;
  });
};

export const isLessThanTwentyPercent = (givenQuantity, totalQuantity) => {
  const twentyPercent = totalQuantity * 0.2;
  return Number(givenQuantity) <= twentyPercent;
};

export const filterItemsOlderThanSixMonths = (medicine) => {
  // console.log(medicine, "medicine");
  const { stockEnteredDate, unitsInStock, deadStockQuantityCheck } = medicine;
  const salePercentage =
    ((deadStockQuantityCheck - unitsInStock) / deadStockQuantityCheck) * 100;
  const currentDate = new Date();
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  return (
    stockEnteredDate < sixMonthsAgo.valueOf() &&
    1 <= salePercentage &&
    salePercentage <= 20
  );
};

export const getUndefinded = (data) => {
  let undefindedValues = [];
  for (const key in data) {
    if (
      data[key] === undefined ||
      data[key] === "undefined" ||
      data[key] === ""
    )
      undefindedValues.push(key);
  }
  return undefindedValues;
};

export const hasMoreThanTwoUniqueTrueKeys = (obj) => {
  // Get all keys that have a value of true
  const trueKeys = Object.keys(obj).filter((key) => obj[key]);

  // Use a Set to ensure unique keys
  const uniqueTrueKeys = new Set(trueKeys);

  // Check if the number of unique true keys is greater than two
  return uniqueTrueKeys.size > 2;
};
export const filterListByIds = (list, keyObj) => {
  // Get the IDs from keyObj that have true values
  const validIds = Object.keys(keyObj).filter((key) => keyObj[key]);

  // Filter the list based on these IDs
  return list.filter((item) => validIds.includes(item.id));
};
export const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const prepareFormDataUsingSchema = (
  schema,
  selectedValues,
  pharmacological_names = [],
  brand_names = []
) => {
  // Find the pharmacologicalName and brandName IDs based on selected values
  const pName = pharmacological_names.find(
    ({ name }) => name === selectedValues.pharmacologicalName
  );
  const pName_id = pName ? pName.id : null;

  const bName = brand_names.find(
    ({ name }) => name === selectedValues.brandName
  );
  const formObj = formOptions.find(
    ({ value }) => value?.toLowerCase() === selectedValues?.form?.toLowerCase()
  );
  const bName_id = bName ? bName.id : null;

  const formData = {};

  schema.forEach((field) => {
    const { name, type } = field;

    if (name === "pharmacologicalName" && pName_id) {
      // Set the pharmacologicalName value based on pName_id
      formData[name] = {
        value: pName_id,
        label: pName.name, // Set label to the name
      };
    } else if (name === "brandName" && bName_id) {
      // Set the brandName value based on bName_id
      formData[name] = {
        value: bName_id,
        label: bName.name, // Set label to the name
      };
    } else if (name === "form") {
      // const formObj = formOptions.find(({value}) => value === schema[name]);
      formData[name] = {
        value: formObj.value,
        label: formObj.label, // Set label to the name
      };
    } else if (type === "select") {
      // Handle other select fields
      formData[name] = selectedValues[name]
        ? selectedValues[name]
        : { value: "", label: "Select" };
    } else {
      // For other types (e.g., text/number fields)
      formData[name] = selectedValues[name] || ""; // Set value or empty string
    }
  });

  return formData;
};

export const logWithFile = (...args) => {
  // Create a new Error object to capture the stack trace
  const stack = new Error().stack;

  // Extract the relevant line of the stack trace (the one that called logWithFile)
  const stackLine = stack.split("\n")[2] || ""; // Line 2 in the stack usually contains caller information

  // Extract the file name and line number from the stack trace
  const match = stackLine.match(/\((.*):(\d+):(\d+)\)/);

  let fileInfo = "";
  if (match) {
    const filePath = match[1];
    const lineNumber = match[2];
    const columnNumber = match[3];

    // Extract just the file name from the file path
    const fileName = filePath.split("/").pop();

    // Format file info
    fileInfo = `${fileName}:${lineNumber}:${columnNumber}`;
  }

  // Log the file name and line number along with the actual log message
  // console.log(`[${fileInfo}]`, ...args);
};

export const addRupeeSymbol = (amount) => {
  if (amount === undefined || amount === null || isNaN(amount)) return ""; // Handle edge cases

  // Convert the input into a number and format it using `toLocaleString` with Indian currency formatting
  const formattedAmount = Number(amount).toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2, // Set to 2 decimal places
  });

  return formattedAmount; // Returns formatted value with rupee symbol
};

export const generatePRUid = (name, count = 0) => {
  let vendorName = name?.slice(0, 3);
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  const fromattedNumber = String(count + 1).padStart(3, "0");
  return `PR-${vendorName}-${year}${month}${day}-${fromattedNumber}`;
};

export const useDraggable = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const elementRef = useRef(null);

  const handleDrag = (e, ui) => {
    setPosition((prev) => ({
      x: prev.x + ui.deltaX,
      y: prev.y + ui.deltaY,
    }));
  };

  const handleStop = () => {
    if (elementRef.current) {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const elementRect = elementRef.current.getBoundingClientRect();
      const elementWidth = elementRect.width;
      const elementHeight = elementRect.height;

      let finalX = position.x;
      let finalY = position.y;

      // Check if the element is out of bounds
      const isOutOfBounds =
        elementRect.left < 0 ||
        elementRect.right > windowWidth ||
        elementRect.top < 0 ||
        elementRect.bottom > windowHeight;

      // Snap to edges if out of bounds
      if (isOutOfBounds) {
        if (elementRect.left < 0) {
          finalX = 0; // Snap to left edge
        } else if (elementRect.right > windowWidth) {
          finalX = windowWidth - elementWidth; // Snap to right edge
        }
        if (elementRect.top < 0) {
          finalY = 0; // Snap to top edge
        } else if (elementRect.bottom > windowHeight) {
          finalY = windowHeight - elementHeight; // Snap to bottom edge
        }
      }

      setPosition({ x: finalX, y: finalY });
    }
  };

  return { position, setPosition, elementRef, handleDrag, handleStop };
};

export function countTodayEntries(list = []) {
  const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
  // console.log(list.filter((item) => item.date === today).length, "count");

  return list.filter((item) => item.date === today).length;
}

export const handleCommaDelimiterChange = (e, setValue, name) => {
  // Check if setValue is a function
  if (typeof setValue !== "function") {
    console.error("Invalid setValue passed to handleCommaDelimiterChange");
    return;
  }

  // Ensure e.target and e.target.value are defined before using replace
  if (!e || !e.target || typeof e.target.value !== "string") {
    console.error(
      "Invalid event object passed to handleCommaDelimiterChange:",
      e
    );
    setValue(name, ""); // Reset the value to an empty string if the event is invalid
    return;
  }

  // Get the value and remove commas
  let value = e.target.value.replace(/,/g, ""); // Remove commas for processing

  // If the value is empty, clear the input field
  if (value.trim() === "") {
    setValue(name, "");
    return;
  }

  // Allow only numbers and one decimal point
  const validValue = value.match(/^\d*\.?\d*$/);

  if (!validValue) {
    setValue(name, value); // If invalid input, return the raw value
    return;
  }

  // Convert the value to a number and check if it's a valid number
  const numericValue = Number(value);

  if (!isNaN(numericValue)) {
    // If the value contains a decimal, ensure the comma is placed correctly
    let formattedValue;
    if (value.includes(".")) {
      // Format with commas but ensure the decimal part stays intact
      let [integer, decimal] = value.split(".");
      formattedValue =
        parseInt(integer).toLocaleString("en-IN") + "." + decimal;
    } else {
      // Format with commas for integer values
      formattedValue = parseInt(value).toLocaleString("en-IN");
    }

    setValue(name, formattedValue);
  } else {
    // If the input is invalid, set the value back to the input (without commas)
    setValue(name, value);
  }
};

export const parseNumber = (value) => {
  if (typeof value === "string") {
    const parsedValue = Number(value.replace(/,/g, "")); // Remove commas and convert to number
    return isNaN(parsedValue) ? 0 : parsedValue; // Return 0 if the result is NaN
  }
  return isNaN(value) ? 0 : Number(value); // Return 0 if value is NaN
};

export const bestPricingOrder = (medicines) => {
  const groupedByPharmacologicalName = medicines.reduce((acc, item) => {
    if (!acc[item.pharmacologicalName]) {
      acc[item.pharmacologicalName] = [];
    }
    acc[item.pharmacologicalName].push(item);
    return acc;
  }, {});

  for (let pharmacologicalName in groupedByPharmacologicalName) {
    // Sort by discount in descending order
    groupedByPharmacologicalName[pharmacologicalName].sort(
      (a, b) => parseFloat(b.discount || 0) - parseFloat(a.discount || 0)
    );

    let labelMap = new Map(); // Tracks discount-to-label mappings
    let currentLabel = 1; // Starts with L1

    groupedByPharmacologicalName[pharmacologicalName].forEach((item) => {
      const discount = parseFloat(item.discount || 0);

      // Check if discount already has a label
      if (!labelMap.has(discount)) {
        if (currentLabel <= 5) {
          labelMap.set(discount, `L${currentLabel}`);
          currentLabel++;
        } else {
          // If all labels are used, assign L5 to subsequent discounts
          labelMap.set(discount, `L5`);
        }
      }

      // Assign the corresponding pricingOrder
      item.pricingOrder = labelMap.get(discount);
    });
  }

  // Flatten grouped data and sort alphabetically by pharmacologicalName
  const flatArray = Object.values(groupedByPharmacologicalName).flat();
  flatArray.sort((a, b) =>
    a.pharmacologicalName.localeCompare(b.pharmacologicalName)
  );

  return flatArray;
};




export const filteringLatestTimeStamp = (map) => {
  const result = new Map();

  map.forEach((medicines, key) => {
    const brandNameMap = new Map();

    medicines.forEach((medicine) => {
      const { brandName, form, dose, createdTime } = medicine;

      // Validate required fields
      // if (!brandName || !form || !dose || !createdTime) {
      //   console.warn(`Invalid medicine entry:`, medicine);
      //   return;
      // }

      const normalizedKey = normalizeString(brandName, form, dose);
      const existingMedicine = brandNameMap.get(normalizedKey);

      if (existingMedicine) {
        const { createdTime: existingTime } = existingMedicine;

        // Compare timestamps and update if the current medicine is more recent
        if (
          createdTime.seconds > existingTime.seconds ||
          (createdTime.seconds === existingTime.seconds &&
            createdTime.nanoseconds > existingTime.nanoseconds)
        ) {
          brandNameMap.set(normalizedKey, medicine);
        }
      } else {
        // Add new normalized key to the map
        brandNameMap.set(normalizedKey, medicine);
      }
    });

    result.set(key, Array.from(brandNameMap.values()));
  });

  const finalResult = Array.from(result.values()).flat();
  console.log(finalResult, "filteringLatestTimeStamp");
  return finalResult;
};

export const normalizeString = (...args) => {
  return args.map((str) => str.toString().trim().toLowerCase()).join("");
};
