import React, { useState, useEffect } from "react";
import "./DataGrid.css";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { addRupeeSymbol } from "../../utils/Utils";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";


export default function DataGrid({
  showCheckbox = false,
  columns = [],
  rows = [],
  onEdit,
  onDelete, // New callback prop for delete action
  showActions = true,
  editedRowIndex,
  onSelectionChange = () => {}, // Callback prop to send selected rows to parent
}) {
  const [selectedRows, setSelectedRows] = useState({});
  // Toggle selection for a specific row
  const handleRowSelect = (rowId) => {
    setSelectedRows((prevSelected) => {
      const newSelection = {
        ...prevSelected,
        [rowId]: !prevSelected[rowId],
      };
      // Call the callback with the new selection
      onSelectionChange(newSelection);
      return newSelection;
    });
  };

  // Select or deselect all rows
  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    const newSelectedRows = rows.reduce((acc, row, index) => {
      acc[index] = isChecked;
      return acc;
    }, {});
    setSelectedRows(newSelectedRows);
    // Call the callback with the new selection
    onSelectionChange(newSelectedRows);
  };

  // Get the selected rows in the required format
  const getSelectedRows = () => {
    return rows.reduce((acc, row, index) => {
      acc[row.id] = !!selectedRows[index];
      return acc;
    }, {});
  };

  useEffect(() => {
    // Initial call to send the current selection to the parent
    onSelectionChange(getSelectedRows());
  }, [selectedRows]);

  const addCurrency = (name, value) => {
    const list = [
      "mrp", "ptr", "pts", "mrpPerStrip", "pricePerStrip", "pricePerUnit", "price", "total", "amount"
    ];

    if (!value) {
      // If value is undefined, null, or empty, return an empty string
      return "";
    }

    const numericValue = Number(value.toString().replace(/,/g, "")); // Convert value to string and remove commas

    if (list.includes(name) && !isNaN(numericValue)) {
      // Format the value with commas and prepend the rupee symbol
      const formattedValue = numericValue.toFixed(2).toLocaleString("en-IN");
      return `₹${formattedValue}`; // Properly prepend the Rupee symbol
    }

    return value; // Return the value as is for non-numeric or non-targeted fields
};

  
  return (
    <Paper sx={{ width: "100%"}}>
      <TableContainer>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {showCheckbox && (
                <TableCell>
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    checked={
                      rows.length > 0 &&
                      Object.keys(selectedRows).length === rows.length &&
                      rows.every((_, index) => selectedRows[index])
                    }
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{ minWidth: column.minWidth,
                    // padding: "10px 3px",
                   }}
                >
                  {column.Header}
                </TableCell>
              ))}
              {showActions && <TableCell>Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                style={{
                  backgroundColor:
                    rowIndex === editedRowIndex ? "#c2dbff" : "white",
                }}
              >
                {showCheckbox && (
                  <TableCell>
                    <input
                      type="checkbox"
                      checked={!!selectedRows[rowIndex]}
                      onChange={() => handleRowSelect(rowIndex)}
                    />
                  </TableCell>
                )}
                {columns.map((column) => {
                  const value = row[column.accessor];
                  return (
                    <TableCell sx={{
                      textTransform: column.accessor === "email" ? "none" : "capitalize"
                    }} key={column.id}>
                      {column.format && typeof value === "number"
                        ? addCurrency(column.accessor, column.format(value))
                        : addCurrency(column.accessor, value)}
                    </TableCell>
                  );
                })}
                {showActions && (
                  <TableCell>
                    <ModeEditIcon
                      sx={{
                        color: "#FFB91DFF",
                      }}
                      onClick={() => onEdit(row, rowIndex)}
                    />
                    <DeleteOutlineIcon
                      sx={{
                        color: "red",
                      }}
                      onClick={() => onDelete(rowIndex)}
                    />
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
