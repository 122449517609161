export const PurchaseRequisitionColumns = [
  {
    Header: "Pharmacological Name",
    accessor: "pharmacologicalName",
  },
  {
    Header: "Brand Name",
    accessor: "brandName",
  },
  {
    Header: "Dose",
    accessor: "dose",
  },
  {
    Header: "Form",
    accessor: "form",
  },
  {
    Header: "Quantity / Strips",
    accessor: "quantity",
  },
];

export const BillingColumns = [
  {
    Header: "Medicine Name",
    accessor: "brandName",
  },
  {
    Header: "Batch No",
    accessor: "batchNo",
  },
  {
    Header: "HSN Code",
    accessor: "hsnCode",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Qty",
    accessor: "quantity",
  },
  {
    Header: "Total",
    accessor: "total",
  },
  {
    Header: "Discount %",
    accessor: "discount",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
];

export const BestPriceOrderListColumns = [
  {
    Header: "Vendor Name",
    accessor: "vendorName",
  },
  {
    Header: "Pricing Order",
    accessor: "pricingOrder",
  },
  {
    Header: "Pharmacological Name",
    accessor: "pharmacologicalName",
  },
  {
    Header: "Brand Name",
    accessor: "brandName",
  },
  {
    Header: "Dose",
    accessor: "dose",
  },
  {
    Header: "Form",
    accessor: "form",
  },
  {
    Header: "Quantity / Strips",
    accessor: "quantity",
  },
  {
    Header: "MRP",
    accessor: "mrp",
  },
  {
    Header: "PTR",
    accessor: "ptr",
  },
  {
    Header: "PTS",
    accessor: "pts",
  },
  {
    Header: "GST%",
    accessor: "gst",
  },
  {
    Header: "Discount%",
    accessor: "discount",
  },
];
export const QuotationColumns = [
  {
    Header: "Pharmacological Name",
    accessor: "pharmacologicalName",
  },
  {
    Header: "Brand Name",
    accessor: "brandName",
  },
  {
    Header: "Dose",
    accessor: "dose",
  },
  {
    Header: "Form",
    accessor: "form",
  },
  {
    Header: "Quantity / Strips",
    accessor: "quantity",
  },
  {
    Header: "MRP",
    accessor: "mrp",
  },
  {
    Header: "PTR",
    accessor: "ptr",
  },
  {
    Header: "PTS",
    accessor: "pts",
  },
  {
    Header: "GST%",
    accessor: "gst",
  },
  {
    Header: "Discount%",
    accessor: "discount",
  },
];

export const RequisionColumnQuotation = [
  {
    Header: "Vendor Name",
    accessor: "vendorName",
  },
  {
    Header: "Ordered Date",
    accessor: "date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
];

export const InventoryColumns = [
  { Header: "Pricing Order", accessor: "pricingOrder" },
  {
    Header: "Invoice No",
    accessor: "invoiceNumber",
  },
  {
    Header: "Medicine Name",
    accessor: "brandName",
  },
  {
    Header: "Batch Number",
    accessor: "batchNo",
  },
  {
    Header: "HSN code",
    accessor: "hsnCode",
  },
  {
    Header: "Expiry",
    accessor: "expiry",
  },
  {
    Header: "MRP",
    accessor: "mrpPerStrip",
  },
  {
    Header: "Discount%",
    accessor: "discount",
  },
  {
    Header: "Price",
    accessor: "pricePerStrip",
  },
  {
    Header: "Units Per Strip",
    accessor: "unitsOrStrips",
  },
  {
    Header: "Price Per Unit",
    accessor: "pricePerUnit",
  },
  {
    Header: "Units In Stock",
    accessor: "unitsInStock",
  },
  {
    Header: "GST %",
    accessor: "gst",
  },
];
export const invoiceColumns = [
  {
    Header: "Brand Name",
    accessor: "brandName",
  },
  {
    Header: "Batch No",
    accessor: "batchNo",
  },
  {
    Header: "HSN Code",
    accessor: "hsnCode",
  },
  {
    Header: "Expiry",
    accessor: "expiry",
  },
  {
    Header: "Units / Strips",
    accessor: "unitsOrStrips",
  },
  {
    Header: "Total Strips",
    accessor: "noOfStrips",
  },
  {
    Header: "MRP per Strip",
    accessor: "mrpPerStrip",
  },
  {
    Header: "Price per Strip",
    accessor: "pricePerStrip",
  },
  {
    Header: "GST %",
    accessor: "gst",
  },
  {
    Header: "Tax in",
    accessor: "taxIn",
  },
  {
    Header: "Net Price",
    accessor: "netPrice",
  },
];

export const PurchaseOrderColumns = [
  { field: "vendorName", Header: "Vendor Name", flex: 1, editable: false },
  { field: "pricingOrder", Header: "Pricing Order", flex: 1, editable: false },
  {
    field: "pharmacologicalName",
    Header: "Pharmacological Name",
    flex: 1,
    editable: false,
  },
  {
    field: "brandName",
    Header: "Brand Name",
    flex: 1,
    align: "left",
    headerAlign: "left",
    editable: false,
  },
  { field: "dose", Header: "Dose", flex: 0.5, editable: false },
  { field: "form", Header: "Form", flex: 0.5, editable: false },
  {
    field: "quantity",
    Header: "Quantity / Strips",
    flex: 0.5,
    editable: true,
  },
];

export const pdfColumns = [
  {
    Header: "Pharmacological Name",
    accessor: "pharmacologicalName",
  },
  {
    Header: "Brand Name",
    accessor: "brandName",
  },
  {
    Header: "Dose",
    accessor: "dose",
  },
  {
    Header: "Form",
    accessor: "form",
  },
  {
    Header: "Quantity / Strips",
    accessor: "quantity",
  },
];
export const ReturnListColumns = [
  {
    Header: "Vendor Name",
    accessor: "vendorName",
  },
  {
    Header: "Return Date",
    accessor: "returnDate",
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
  },
  {
    Header: "Return Qty",
    accessor: "quantity",
  },
  {
    Header: "Return Amount",
    accessor: "returnAmount",
  },
  {
    Header: "View",
    accessor: "view",
  },
];

export const PaymentListColumns = [
  {
    Header: "Vendor Name",
    accessor: "vendorName",
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
  },
  {
    Header: "Paid Amount",
    accessor: "paidAmount",
  },
  {
    Header: "Due Amount",
    accessor: "dueAmount",
  },
  {
    Header: "Invoice Due Date",
    accessor: "invoiceDueDate",
  },
  {
    Header: "View",
    accessor: "view",
  },
];

export const InvoiceListColumns = [
  {
    Header: "Vendor Name",
    accessor: "vendorName",
  },
  {
    Header: "Invoice Date",
    accessor: "invoiceDate",
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
  },
  {
    Header: "Balance Amount",
    accessor: "balanceAmount",
  },
  {
    Header: "Remarks",
    accessor: "remarks",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
export const VendorListColumns = [
  {
    Header: "Vendor Name",
    accessor: "vendorName",
  },
  {
    Header: "Phone Number",
    accessor: "phone",
  },
  {
    Header: "GST Number",
    accessor: "gstNumber",
  },
  {
    Header: "Mail Id",
    accessor: "email",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  // {
  //   Header: "Actions",
  //   accessor: "actions",
  // },
];
export const InventoryReportColumns = [
  {
    Header: "Pharmacological Name",
    accessor: "pharmacologicalName",
  },
  {
    Header: "Medicine Name",
    accessor: "medicineName",
  },
  {
    Header: "Invoice No",
    accessor: "invoiceNo",
  },
  {
    Header: "Batch No",
    accessor: "batchNo",
  },
  {
    Header: "Invoice Amount",
    accessor: "invoiceAmount",
  },
  {
    Header: "Total Qty",
    accessor: "totalQuantity",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "MRP",
    accessor: "mrp",
  },
  {
    Header: "Units in Stock",
    accessor: "unitsInStock",
  },
  {
    Header: "Expiry",
    accessor: "expiry",
  },
  {
    Header: "GST",
    accessor: "gst",
  },
  {
    Header: "Minimal Order Qty",
    accessor: "minimalOrderQuantity",
  },
  {
    Header: "Return",
    accessor: "return",
  },
];
export const SalesReportColumns = [
  {
    Header: "Patient Name",
    accessor: "patientName",
  },
  {
    Header: "Medicine Name",
    accessor: "medicineName",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "MRP",
    accessor: "mrp",
  },
  {
    Header: "Price",
    accessor: "price",
  },
  {
    Header: "Total MRP",
    accessor: "totalMrp",
  },
  {
    Header: "Total Price",
    accessor: "totalPrice",
  },
  {
    Header: "Discount",
    accessor: "discount",
  },
  {
    Header: "Profit Margin",
    accessor: "profitMargin",
  },
  {
    Header: "OTC/Rx",
    accessor: "otc",
  },
  {
    Header: "Cash/UPI",
    accessor: "cash",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
